const koinzCountries = {
  eg: "EGY",
  sa: "SAU",
  ae: "UAE",
  om: "OMN",
  in: "IND",
  kw: "KWT",
  bh: "BHR",
};
export default koinzCountries;
